@mixin set_transition ($ms : 400ms, $type : ease-out){
	-webkit-transition: $type $ms;
    -moz-transition: 	$type $ms;
    -ms-transition: 	$type $ms;
    -o-transition: 		$type $ms;
    transition: 		$type $ms;
}

@mixin disable_select(){
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	 -khtml-user-select: none; 
	   -moz-user-select: none;
		-ms-user-select: none;
			user-select: none;
}
