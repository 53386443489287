$default_font: 			"Porsche Next TT",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif;
$default_font_color:	#000000;
$body_min_width:		360px;
   
@import "vendors/bootstrap";
@import "mixins";

@import "fonts";
@import "common";

@import "pages/home";
@import "pages/connect";
@import "pages/mobility";
@import "pages/digital_sales";
@import "pages/portfolio_processes";
@import "pages/e-services";
