/*
Portfolio & Processes
*/
.content.portfolio_processes{
	h1{
		&:before,
		&:after{
			width: 10%;

			@include media-breakpoint-up(md) {
				width: 14%;
			}
			@include media-breakpoint-up(lg) {
				width: 24%;
			}
			@include media-breakpoint-up(xl) {
				width: 29%;
			}
		}
	}

	.services{
		margin-bottom: 64px;

		@include media-breakpoint-up(md) {
			justify-content: center;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 102px;
		}

		h2{
			@include media-breakpoint-down(sm) {
				font-size: 28px;
			    margin-bottom: 24px;
			}
			@include media-breakpoint-between(md, md) {
				font-size: 18px;
			    margin-bottom: 28px;
			}
			@include media-breakpoint-between(lg, lg) {
				font-size: 24px;
			}

			&:before,
			&:after{
				@include media-breakpoint-down(sm) {
					top: 15px;
				}
				@include media-breakpoint-between(md, md) {
					top: 8px;
				}
				@include media-breakpoint-between(lg, lg) {
					top: 12px;
				}
			}
		}

		& > div{
			@include media-breakpoint-down(sm) {
				margin-bottom: 36px;
			}

			&:nth-child(1){
				margin-bottom: 52px;

				h2{
					&:before,
					&:after{
						width: 7%;
					}
				}
			}
			&:nth-child(2){
				margin-bottom: 52px;

				h2{
					&:before,
					&:after{
						width: 7%;
					}
				}
			}
			&:nth-child(3){
				margin-bottom: 52px;

				h2{
					&:before,
					&:after{
						width: 34%;
					}
				}
			}
			&:nth-child(4){
				h2{
					&:before,
					&:after{
						width: 26%;
					}
				}
			}
			&:nth-child(5){
				h2{
					&:before,
					&:after{
						width: 18%;
					}
				}
			}
		}

		.item{
			img{
				margin-bottom: 22px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.vx_collaboration{
		h2{
			&:before,
			&:after{
				width: 5%;

				@include media-breakpoint-up(md) {
					width: 10%;
				}
				@include media-breakpoint-up(lg) {
					width: 9%;
				}
				@include media-breakpoint-up(xl) {
					width: 19%;
				}
			}
		}

		.row.overview{
			margin-bottom: 14px;

			@include media-breakpoint-up(md) {
				margin-bottom: 40px;
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 54px;
			}
		}

		.row.item{
			align-items: center;
			position: relative;
			margin-bottom: 44px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 64px;
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 64px;
			}

			img{
				@include media-breakpoint-down(sm) {
					margin-left: auto;
				    margin-right: auto;
				    display: block;
				    margin-bottom: 12px;
				}
			}

			&:not(:last-child):after{
				content: '';
				width: 18px;
				height: 18px;
				background: url(../images/portfolio_processes/icon_next.png);
				position: absolute;
				left: 68px;
    			bottom: -34px;
				
				@include media-breakpoint-down(sm) {
					left: 48%;
					left: calc(50% - 14px);
				}
				@include media-breakpoint-up(md) {
					left: 76px;
    				bottom: -30px;
				}
				@include media-breakpoint-up(lg) {
					left: 68px;
    				bottom: -34px;
				}
				@include media-breakpoint-up(xl) {
					left: 76px;
					bottom: -44px;
				}
			}
		}
	}

}