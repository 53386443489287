@font-face {
  font-family: 'Porsche Next TT';
  src: url('../fonts/PorscheNextWLa-Bold.eot');
  src: url('../fonts/PorscheNextWLa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PorscheNextWLa-Bold.woff2') format('woff2'),
    url('../fonts/PorscheNextWLa-Bold.woff') format('woff'),
    url('../fonts/PorscheNextWLa-Bold.ttf') format('truetype'),
    url('../fonts/PorscheNextWLa-Bold.svg#') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Porsche Next TT';
  src: url('../fonts/PorscheNextWLa-Regular.eot');
  src: url('../fonts/PorscheNextWLa-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PorscheNextWLa-Regular.woff2') format('woff2'),
    url('../fonts/PorscheNextWLa-Regular.woff') format('woff'),
    url('../fonts/PorscheNextWLa-Regular.ttf') format('truetype'),
    url('../fonts/PorscheNextWLa-Regular.svg#') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
