/*
Connect page
*/
.content.connect{
	h1{
		&:before,
		&:after{
			width: 0%;

			@include media-breakpoint-up(md) {
				width: 18%;
			}
			@include media-breakpoint-up(lg) {
				width: 28%;
			}
			@include media-breakpoint-up(xl) {
				width: 33%;
			}
		}
	}

	.set_and_using{
		text-align: center;
		margin-bottom: 36px;

		@include media-breakpoint-up(md) {
			margin-bottom: 72px;
		}

		h2{
			@include media-breakpoint-down(sm) {
				font-size: 28px;
				margin-bottom: 24px;
			}
			@include media-breakpoint-between(md, md) {
				font-size: 18px;
				margin-bottom: 18px;
			}
			@include media-breakpoint-between(lg, lg) {
				font-size: 25px;
				margin-bottom: 24px;
			}

			&:before,
			&:after{
				top: 26px;

				@include media-breakpoint-up(md) {
					top: 19px;
				}
				@include media-breakpoint-up(lg) {
					top: 24px;
				}
				@include media-breakpoint-up(xl) {
					top: 35px;
				}
			}
		}

		& > div{
			@include media-breakpoint-down(sm) {
				margin-bottom: 42px;
			}

			&:nth-child(1){
				h2{
					&:before,
					&:after{
						width: 7%;
					}
				}
			}
			&:nth-child(2){
				h2{
					&:before,
					&:after{
						width: 4%;
					}
				}
			}
			&:nth-child(3){
				h2{
					&:before,
					&:after{
						width: 7%;
					}
				}
			}
		}

		.btn{
			margin-top: 22px;
		}
	}
	.leasing{
		h2{
			&:before,
			&:after{
				width: 5%;

				@include media-breakpoint-up(md) {
					width: 15%;
				}
				@include media-breakpoint-up(lg) {
					width: 11%;
				}
				@include media-breakpoint-up(xl) {
					width: 20%;
				}
			}
		}

		.video_wrapper{
			margin-top: 44px;

			@include media-breakpoint-up(lg) {
				margin-top: 62px;
			}

			video{
				cursor: pointer;
			}
		}
	}
}