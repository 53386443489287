html{
    height: 100%;
}

body{
    min-height: 100%;
    min-width: $body_min_width;
    font-family: $default_font;
    color: $default_font_color;
}

a,
a:hover,
a:active,
img,
img:hover,
img:active{
	@include set_transition();
}


h2, 
h3{
	position: relative;
	text-align: center;
	line-height: 1;
	margin-bottom: 42px;
	font-weight: 700;
	font-size: 20px;

	@include media-breakpoint-up(lg) {
		font-size: 32px;
	}

	&:before,
	&:after{
		content: '';
		position: absolute;
		left: 0;
	    top: 10px;
		width: 40%;
		height: 1px;
		background-color: #b4b4b4;

		@include media-breakpoint-up(lg) {
	    	top: 16px;
		}
	}
	&:after{
		left: auto;
		right: 0;
	}
}

h3{
	font-size: 20px;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg) {
		font-size: 22px;
	}

	&:before,
	&:after{
		top: 10px;
	}
}

h4{
	font-size: 22px;
	font-weight: 700;
}

h1, .h1{
	position: relative;
	text-align: center;
	line-height: 1;
	margin-top: 18px;
	margin-bottom: 48px;
	font-size: 48px;
	font-weight: 700;

	&:before,
	&:after{
		content: '';
		position: absolute;
		left: 0;
	    top: 24px;
		width: 15%;
		height: 1px;
		background-color: #b4b4b4;

		@include media-breakpoint-up(md) {
			width: 33%;
		}
		@include media-breakpoint-up(lg) {
			width: 37%;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
	}
	&:after{
		left: auto;
		right: 0;
	}
}

.container{
	@include media-breakpoint-up(lg) {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	@include media-breakpoint-up(xl) {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}

.title_section{
	.img{
    	background-position: center top;
    	background-size: cover;
    	height: 117px;

    	@include media-breakpoint-up(md) {
			margin-left: -25px;
    		margin-right: -25px;
		}
	}

	.icon{
		width: 109px;
		height: 109px;
		background-color: #336699;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -61px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		z-index: 1;
	}
}

.content{
	margin-bottom: 46px;
	background: url(../images/page_bg.jpg);
	background-position: center -18px;
	background-repeat: no-repeat;
    background-size: 100%;

	@include media-breakpoint-up(md) {
		padding-left: 25px;
		padding-right: 25px;
	}

	p{
		margin-bottom: 24px;
	}

	br{
		&.md{
			display: none;

			@include media-breakpoint-between(md, md) {
				display: inline-block !important;
			}
		}
		&.lg{
			display: none;

			@include media-breakpoint-between(lg, lg) {
				display: inline-block !important;
			}
		}
		&.xl{
			display: none;

			@include media-breakpoint-up(xl) {
				display: inline-block;
			}
		}
	}

	a.link{
		color: #000;
		font-weight: 700;
		text-decoration: underline;

		&:active,
		&:hover{
			text-decoration: none;
		}
	}

	.btn{
	    background-color: #336699;
	    height: 52px;
	    padding: 18px 58px 14px 26px;
	    color: #fff;
	    line-height: 1;
	    text-align: center;
	    border-radius: 0;
	    position: relative;
	    border: 0;
	    margin-top: 6px;

		&:after{
		    content: '';
		    background: url(../images/icon_btn_right.png);
		    background-repeat: no-repeat;
		    width: 18px;
		    height: 18px;
		    position: absolute;
		    right: 26px;
		    top: 17px;
		}
	}

	.main_content{
		margin-bottom: 72px;
		
		& > div:nth-child(2){
			@include media-breakpoint-down(sm) {
				margin-top: 22px;
			}
			@include media-breakpoint-up(xl) {
				padding-left: 15px;
			}
		}
	}
}