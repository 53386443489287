/*
Mobility page
*/
.content.mobility{

	h1{
		&:before,
		&:after{
			@include media-breakpoint-up(md) {
				width: 22%;
			}
			@include media-breakpoint-up(lg) {
				width: 31%;
			}
			@include media-breakpoint-up(xl) {
				width: 33%;
			}
		}
	}

	.services{
		text-align: center;
		margin-bottom: 36px;

		@include media-breakpoint-between(md, md) {
			justify-content: space-between;
		}
		@include media-breakpoint-between(lg, lg) {
			justify-content: space-around;
		}
		@include media-breakpoint-up(md) {
			margin-bottom: 64px;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 92px;
		}

		& > div{
			@include media-breakpoint-down(sm) {
				margin-bottom: 36px;
			    flex: 0 0 280px;
			    margin-left: auto;
			    margin-right: auto;
			}
			@include media-breakpoint-between(md, md) {
				flex: 0 0 45%;
				max-width: 45%;
			}
			@include media-breakpoint-between(lg, lg) {
				flex: 0 0 35%;
				max-width: 35%;
			}

			&:nth-child(1){
				@include media-breakpoint-between(md, lg) {
					margin-bottom: 48px;
				}

				h3{
					&:before,
					&:after{
						width: 23%;
					}
				}
			}
			&:nth-child(2){
				@include media-breakpoint-between(md, lg) {
					margin-bottom: 48px;
				}

				h3{
					&:before,
					&:after{
						width: 17%;
					}
				}
			}
			&:nth-child(3){
				h3{
					&:before,
					&:after{
						width: 25%;
					}
				}
			}
			&:nth-child(4){
				h3{
					&:before,
					&:after{
						width: 6%;
					}
				}
			}
		}

		.btn{
			margin-top: 22px;
		}
	}
	
	.porsche_drive{

		h2{
			margin-bottom: 36px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 54px;
			}

			&:before,
			&:after{
				width: 9%;

				@include media-breakpoint-up(md) {
					width: 25%;
				}
				@include media-breakpoint-up(lg) {
					width: 31%;
				}
				@include media-breakpoint-up(xl) {
					width: 36%;
				}
			}
		}
		h4{
			margin-bottom: 0;

			@include media-breakpoint-up(xl) {
				margin-top: 20px;
			}
		}

		.main_content{
			margin-bottom: 38px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 58px;
			}
		}

		.functions_list{
			h4{
				margin-top: 30px;
				margin-bottom: 26px;
			}
			.item{
				display: flex;
				margin-bottom: 22px;

				.img{
					flex: 0 0 30px;
					max-width: 30px;
					text-align: center;
				}
				
				.text{
					padding-left: 38px;
					position: relative;
				    padding-top: 1px;

					b{
						display: block;

						span.num{
							position: absolute;
							left: 16px;
						}
					}
				}
				
			}
		}
	}
	
	.porsche_pass{
		h2{
			margin-bottom: 38px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 58px;
			}

			&:before,
			&:after{
				width: 0%;

				@include media-breakpoint-up(md) {
					width: 20%;
				}
				@include media-breakpoint-up(lg) {
					width: 27%;
				}
				@include media-breakpoint-up(xl) {
					width: 33%;
				}
			}
		}

		.main_content{
			margin-bottom: 64px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 92px;
			}

			@include media-breakpoint-up(xl) {
				align-items: center;
			}
		}
	}

	.parken_plus{
		h2{
			margin-bottom: 38px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 58px;
			}

			&:before,
			&:after{
				width: 11%;

				@include media-breakpoint-up(md) {
					width: 28%;
				}
				@include media-breakpoint-up(lg) {
					width: 33%;
				}
				@include media-breakpoint-up(xl) {
					width: 37%;
				}
			}
		}

		.main_content{
			margin-bottom: 64px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 90px;
			}

			@include media-breakpoint-up(xl) {
				align-items: center;
			}
		}
	}

}